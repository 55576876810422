import { BaseService } from "@/core/services/BaseService";

class ActionTemplateGroupService extends BaseService<any> {
  endpoint = '/action-template-group'

  async getListArea(area) {
    return this.http().get(this.endpoint + "/area?area=" +area).then(res => {
      return res.data
    })
  }
  async getList() {
    return this.http().get(this.endpoint).then(res => {
      return res.data
    })
  }

  async setActions(id, actions) {
    return this.http().put(this.endpoint + "/" + id + "/actions", actions).then(res => {
      return res.data
    })
  }
}

export default new ActionTemplateGroupService();