import {BaseService} from "@/core/services/BaseService";

class MetadataService extends BaseService<any> {
  endpoint = "/metadata"

  async getRegions() {
    return this.http().get(this.endpoint + "/phone/regions").then(res => {
      return res.data;
    })
  }
}

export default  new MetadataService();