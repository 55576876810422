import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {IFilter} from "@/core/entity/IFilter";
import {ISearchFilter} from "@/core/entity/ISearchFilter";
import {ISearchField} from "@/core/entity/ISearchField";
import {useRouter} from "vue-router";
import {ICompany} from "@/core/entity/ICompany";
import PolicyService from "@/core/services/PolicyService";
import {ITableResponse} from "@/core/entity/ITableResponse";
import {IPolicy} from "@/core/entity/IPolicy";
import UserService from "@/core/services/UserService";
import {IUser} from "@/core/entity/IUser";
import {IActivity} from "@/core/entity/IActivity";
import ActivityService from "@/core/services/ActivityService";
import CaseService from "@/core/services/CaseService";
import DocumentService from "@/core/services/DocumentService";
import ProductService from "@/core/services/ProductService";
import ProviderService from "@/core/services/ProviderService";
import TemplateService from "@/core/services/TemplateService";
import ClaimService from "@/core/services/ClaimService";
import InvoiceService from "@/core/services/InvoiceService";
import CompanyService from "@/core/services/CompanyService";
import ContentMappingService from "@/core/services/ContentMappingService";
import PageService from "@/core/services/PageService";
import ActionTemplateService from "@/core/services/ActionTemplateService";
import ActionTemplateGroupService from "@/core/services/ActionTemplateGroupService";
import ContactService from "@/core/services/ContactService";
import GroupService from "@/core/services/GroupService";
import TagService from "@/core/services/TagService";
import ServiceService from "@/core/services/ServiceService";
import MetadataService from "@/core/services/MetadataService";


const PAGINATION_SIZE = 50;
export const LoadEntitiesFromEntityEvent = (action: Actions) => {
  const router = useRouter();
  const id = ref(router.currentRoute.value.params.id)
  const store = useStore();
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {},
  })

  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    paginationLoad()
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationLoad()
  };

  const paginationLoad = () => {
    store.dispatch(action, {id: id.value, filter: filter.value})
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
  }
}
export const LoadEntitiesEvent = (action: Actions) => {

  const store = useStore()
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {search: ''},
  })

  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    store.dispatch(action, filter.value)
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    store.dispatch(action, filter.value);
  };

  const paginationLoad = () => {
    store.dispatch(action, filter.value)
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
  }
}

export const SearchEntityEvent = (action: Actions, query: any, fields: string[], expands: string[], entity: string) => {
  const store = useStore();
  const filter = ref<ISearchFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    query: query,
    entity: entity,
    fields: fields,
    expands: expands
  })

  const paginationLoad = () => {
    store.dispatch(action, filter.value)
  }
  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    store.dispatch(action, filter.value)
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationSize = Number(event);
    store.dispatch(action, filter.value);
  };
  const searchEvent = () => {
    paginationLoad();
  }
  const discardEvent = () => {
    filter.value.query = filter.value.query.map(item => Object.assign(item, {
      name: item.name,
      type: item.type,
      column: item.column,
      operator: item.operator,
      value: item.type === 'HIDDEN' ? item.value : ''
    }))
    paginationLoad();
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
    searchEvent,
    discardEvent
  }
}

export const LoadEntity = (action: Actions) => {
  const router = useRouter();
  const id = ref(router.currentRoute.value.params.id)
  const store = useStore();
  const reloadData = () => {
    store.commit(Mutations.SET_ENTITY_LOADING, true)
    store.dispatch(action, id.value)
  }
  const reloadDataWithId = (id) => {
    store.commit(Mutations.SET_ENTITY_LOADING, true)
    store.dispatch(action, id)
  }
  reloadData();
  return {
    reloadData,
    id,
    reloadDataWithId
  }
}

export const HandlePanel = (action: Actions) => {
  const isShow = ref(false);
  const panelLoading = ref(true);
  const store = useStore();
  const onClosePanel = () => {
    isShow.value = false;
  }
  const onOpenPanel = () => {
    isShow.value = true
  }
  const loadPanelData = (id) => {
    panelLoading.value = true
    isShow.value = true
    store.dispatch(action, id).then(() => {
      panelLoading.value = false
    });
  }
  return {
    isShow,
    onClosePanel,
    onOpenPanel,
    loadPanelData,
  }
};


export const HandleState = () => {
  const store = useStore();
  const onCountrySelectedEvent = (country: string) => {
    store.dispatch(Actions.LOAD_STATE, country)
  }
  return {
    onCountrySelectedEvent
  }
}

export const ProductSelect = () => {
  const productFilter = ref({companyId: '', policyId: '', number: ''})
  const products = ref<any>([])
  const loadProducts = () => {
    ProductService.listFilter<any>(productFilter.value).then(res => {
      products.value = res.data
    })
  }
  // loadProducts();
  return {
    loadProducts,
    products,
    productFilter,
  }
}

export const ProviderSelect = () => {
  const providerSelects = ref<any>([])
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {search: '', ep: '', cazeId: ''},
  })
  const loadProvider = () => {
    ProviderService.findAll<any>(filter.value).then(res => {
      providerSelects.value = res.data;
    })
  }
  return {
    providerSelects,
    filter,
    loadProvider,
  }
}
export const ClientSelect = (trident: boolean = false) => {
  const clients = ref<any[]>([])
  const clientFilter = ref({type: 'CLIENT', trident: trident})
  const loadClient = () => {
    CompanyService.listFilter<any>(clientFilter.value).then(res => {
      clients.value = res.data;
    })
  }
  loadClient();
  return {
    loadClient,
    clients,
    clientFilter
  }
}

export const PPOSelect = () => {
  const ppos = ref([])
  const ppoFilter = ref({type: 'PPO'})
  const loadPPO = () => {
    CompanyService.listFilter<any>(ppoFilter.value).then(res => {
      ppos.value = res.data;
    })
  }
  loadPPO();
  return {
    loadPPO,
    ppos,
    ppoFilter
  }
}


export const CompanySelect = () => {
  const store = useStore();

  const loadCompany = () => {
    store.dispatch(Actions.LOAD_CLIENTS)
  }
  loadCompany();
  const clients = computed<any>(() => store.state.CompanyModule.clientSelect)
  return {
    loadCompany,
    clients,
  }
}

export const TagSelect = (objectType, processType = 'MEDICAL_REVIEW') => {
  const tagOptions = ref<any[]>([]);

  const loadTagOptions = () => {
    TagService.loadTags(objectType, processType).then(res => {
      tagOptions.value = res;
    })
  }
  loadTagOptions();
  return {
    tagOptions,
    loadTagOptions,
  }
}
export const SelectAllClient = () => {
  const store = useStore();

  const loadCompany = () => {
    store.dispatch(Actions.LOAD_ALL_CLIENT)
  }
  loadCompany();
  const clients = computed<ICompany[]>(() => store.state.CompanyModule.clientSelect)
  return {
    loadCompany,
    clients,
  }
}

export const PolicySelect = () => {
  const policyFilter = ref({clientId: '', number: '', f: 'all'})
  const policies = ref<any>([])
  const loadPolicy = () => {
    PolicyService.listFilter<ITableResponse<any>>(policyFilter.value).then(res => {
      policies.value = res.data
    })
  }
  loadPolicy()
  return {
    policyFilter,
    loadPolicy,
    policies,
  }
}

export const InsuredSelect = (policyId: string) => {
  const insureds = ref<any>([])
  const loadInsured = (policyId: string) => {
    PolicyService.get<IPolicy>(policyId).then(res => {
      insureds.value = res.insureds;
    })
  }
  if (policyId) {
    loadInsured(policyId)
  }
  return {
    loadInsured,
    insureds
  }
}


export const ActionTemplates = (area) => {
  const actionTemplates = ref<any>([])
  const loadActionTemplate = (area) => {
    ActionTemplateService.list(area).then(res => {
      actionTemplates.value = res;
    })
  }
  const setActionTemplates = (templates) => {
    actionTemplates.value = templates;
  }
  loadActionTemplate(area);
  return {
    actionTemplates,
    setActionTemplates,
    loadActionTemplate,
  }
}

export const ActionTemplatesGroup = (area) => {
  const actionTemplateGroups = ref<any>([])
  const loadActionTemplateGroup = (area) => {
    ActionTemplateGroupService.getListArea(area).then(res => {
      actionTemplateGroups.value = res;
    })
  }
  loadActionTemplateGroup(area);
  return {
    actionTemplateGroups
  }
}

export const CaseSelect = () => {
  const cases = ref<any>([])
  const caseFilter = ref({clientId: '', policyId: '', number: ''})
  const loadCaseSelect = () => {
    CaseService.listFilter<ITableResponse<any>>(caseFilter.value).then(res => {
      cases.value = res.data
    })
  }
  loadCaseSelect();
  return {
    caseFilter,
    cases,
    loadCaseSelect,
  }
}

export const CaseSelectFilter = (filter, isFirstLoad = true) => {
  const cases = ref<any>([])
  const caseFilter = ref(filter)
  const loadCaseSelectFilter = () => {
    CaseService.listFilter<ITableResponse<any>>(caseFilter.value).then(res => {
      cases.value = res.data
    })
  }
  if (isFirstLoad) loadCaseSelectFilter();
  return {
    caseFilter,
    cases,
    loadCaseSelectFilter
  }
}

export const MoveClaimCaseSelect = () => {
  const cases = ref<any>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 100,
    key: {search: '', ep: '', insuredId: '', policyId: ''},
  })
  const loadMoveClaimCase = (policyId, insuredId) => {
    filter.value.key.insuredId = insuredId
    filter.value.key.policyId = policyId

    CaseService.findAll<any>(filter.value).then(res => {
      cases.value = res.data;
    })
  }
  return {
    cases,
    loadMoveClaimCase,
  }
}

export const ClaimSelectFilter = (filter, isFirstLoad = true) => {
  const claims = ref<any>([]);
  const claimFilter = ref(filter)
  const loadClaimSelectFilter = () => {
    ClaimService.listFilter<ITableResponse<any>>(claimFilter.value).then(res => {
      claims.value = res.data;
    })
  }
  if (isFirstLoad) loadClaimSelectFilter()
  return {
    claimFilter,
    claims,
    loadClaimSelectFilter
  }
}

export const InvoiceSelectFilter = (filter, isFirstLoad = true) => {
  const invoices = ref<any>([]);
  const invoiceFilter = ref(filter)
  const loadInvoiceSelectFilter = () => {
    InvoiceService.listFilter<ITableResponse<any>>(invoiceFilter.value).then(res => {
      invoices.value = res.data;
    })
  }
  if (isFirstLoad) loadInvoiceSelectFilter()
  return {
    invoiceFilter,
    invoices,
    loadInvoiceSelectFilter
  }
}

export const ActivityTemplate = () => {
  const templates = ref<any>([]);
  ContentMappingService.getType('ACTIVITY_TEMPLATE').then(res => {
    const ids: any = [];
    res.forEach(item => {
      ids.push(item.key.trim())
    })
    if (ids.length > 0) {
      PageService.docs(ids[0]).then(res => {
        templates.value = res;
      })
    }
  })
  return {
    templates
  }
}

export const EmailTemplate = () => {
  const templates = ref<any>([]);
  ContentMappingService.getType('EMAIL_TEMPLATE').then(res => {
    const ids: any = [];
    res.forEach(item => {
      ids.push(item.key.trim())
    })
    if (ids.length > 0) {
      PageService.docs(ids[0]).then(res => {
        templates.value = res;
      })
    }
  })
  return {
    templates
  }
}

export const UserSelect = () => {
  const users = ref<IUser[]>([])
  const loadUser = () => {
    UserService.listFilter<ITableResponse<IUser>>({ep: 'company'}).then(res => {
      users.value = res.data
    })
  }
  loadUser();
  return {
    users,
    loadUser
  }
}

export const UserSelectGroup = () => {
  const userGroups = ref<any>([])
  const loadUser = () => {
    UserService.groups().then(res => {
      userGroups.value = res
    })
  }
  loadUser();
  return {
    userGroups
  }
}

export const ExaminerSelect = () => {
  const examiners = ref<IUser[]>([])
  const loadExaminerUser = () => {
    UserService.examiners().then(res => {
      examiners.value = res
    })
  }
  loadExaminerUser();
  return {
    examiners,
    loadExaminerUser
  }
}

export const CoordinatorSelect = () => {
  const coordinators = ref<IUser[]>([])
  const loadCoordinatorUser = () => {
    UserService.coordinators().then(res => {
      coordinators.value = res
    })
  }
  loadCoordinatorUser();
  return {
    coordinators,
    loadCoordinatorUser
  }
}

export const CaseManagerSelect = () => {
  const managers = ref<IUser[]>([])
  const loadManagerUser = () => {
    UserService.managers().then(res => {
      managers.value = res
    })
  }
  loadManagerUser();
  return {
    managers,
    loadManagerUser
  }
}
export const TemplateSelect = () => {
  const templates = ref<any>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 100,
    key: {search: '', ep: '', type: 'SCRIPT'},
  })
  const loadTemplate = () => {
    console.log("loadProvider")
    TemplateService.findAll<any>(filter.value).then(res => {
      templates.value = res.data;
    })
  }
  loadTemplate();
  return {
    templates,
    loadTemplate,
  }
}


export const CompanyContact = (companyId) => {
  const contacts = ref<any>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 100,
    key: {search: '', ep: ''},
  })
  const loadCompanyContact = (id) => {
    if (id) {
      filter.value.key.companyId = id;
      ContactService.findAll<any>(filter.value).then(res => {
        contacts.value = res.data;
      })
    }
  }
  loadCompanyContact(companyId);
  return {
    contacts,
    loadCompanyContact,
  }
}

export const TridentContacts = () => {
  const tridentContacts = ref<any>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 100,
    key: {search: '', ep: ''},
  })
  const loadTridentCompanyContact = () => {
      filter.value.key.isTrident = true;
      ContactService.findAll<any>(filter.value).then(res => {
        tridentContacts.value = res.data;
      })

  }
  loadTridentCompanyContact();
  return {
    tridentContacts,
    loadTridentCompanyContact,
  }
}
export const CaseContact = (cazeId) => {
  const caseContacts = ref<any>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 100,
    key: {search: '', ep: ''},
  })
  const loadCaseContact = (id) => {
    if (id) {
      filter.value.key.cazeId = id;
      ContactService.findAll<any>(filter.value).then(res => {
        caseContacts.value = res.data;
      })
    }
  }
  loadCaseContact(cazeId);
  return {
    caseContacts,
    loadCaseContact,
  }
}
export const ServiceSelect = (companyId) => {
  const services = ref<any[]>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 100,
    key: {search: '', ep: '', companyId: ''},
  })
  const loadServices = (id) => {
    if (id) {
      filter.value.key.companyId = id;
      ServiceService.findAll<any>(filter.value).then(res => {
        services.value = res.data;
      })
    }
  }
  loadServices(companyId);
  return {
    services,
    loadServices,
  }
}

export const ClaimContact = (claimId) => {
  const claimContacts = ref<any>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 100,
    key: {search: '', ep: ''},
  })
  const loadClaimContact = (id) => {
    if (id) {
      filter.value.key.claimId = id;
      ContactService.findAll<any>(filter.value).then(res => {
        claimContacts.value = res.data;
      })
    }
  }
  loadClaimContact(claimId);
  return {
    claimContacts,
    loadClaimContact,
  }
}


/**
 * load list activities base on actions and id
 * @param action
 * @constructor
 */
export const LoadActivities = (action: any) => {
  const store = useStore();
  const loadActivities = () => {
    store.dispatch(action.value.action, action.value.id)
  }
  loadActivities();
  return {
    loadActivities,
  }
}


export const TaskDetail = () => {
  const show = ref(false);
  const taskDetail = ref({});
  return {
    show,
    taskDetail,
  }
}
export const ActivityDetail = () => {
  const showDetail = ref(false)
  const activityDetail = ref<IActivity>({} as IActivity)
  const loadDetail = (id) => {
    ActivityService.get<IActivity>(id).then(res => {
      showDetail.value = true
      activityDetail.value = res
    })
  }
  const clearActivityDetail = () => {
    showDetail.value = false
    activityDetail.value = {} as IActivity;
  }
  return {
    showDetail,
    loadDetail,
    activityDetail,
    clearActivityDetail,
  }
}

export const DocumentDetail = () => {
  const showDetail = ref(false)
  const documentDetail = ref<any>({});
  const loadDocDetail = (id) => {
    DocumentService.get<any>(id).then(res => {
      showDetail.value = true
      documentDetail.value = res
    })
  }
  const clearDocumentDetail = () => {
    showDetail.value = false
    documentDetail.value = {}
  }
  return {
    showDetail,
    loadDocDetail,
    documentDetail,
    clearDocumentDetail,
  }
}
export const ProviderDetail = () => {
  const showDetail = ref(false)
  const providerDetail = ref<any>({});
  const loadDetail = (id) => {
    ProviderService.get<any>(id).then(res => {
      showDetail.value = true
      providerDetail.value = res
    })
  }
  const clearDetail = () => {
    showDetail.value = false
    providerDetail.value = {}
  }
  return {
    showDetail,
    loadDetail,
    providerDetail,
    clearDetail,
  }
}

export const LoadFilterObjects = (action: Actions, filterObject: any, expands: any[] = [], sort: string = '') => {
  const store = useStore();
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {search: '', ep: expands.join(","), s: sort},
  })
  filter.value.key = Object.assign(filter.value.key, filterObject);
  const updateFilterObject = (filterObject) => {
    filter.value.key = Object.assign(filter.value.key, filterObject);
    paginationLoad()
  }
  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    paginationLoad()
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationLoad()
  };

  const paginationLoad = () => {
    store.dispatch(action, filter.value);
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
    updateFilterObject,
  }
}
export const CallCenterSearch = (action: Actions, query: ISearchField[], expands: any[] = []) => {
  const store = useStore();
  const search = ref(query)
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {search: '', ep: expands.join(",")},
  })

  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    paginationLoad()
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationLoad()
  };
  const updateFilterObject = () => {
    const temp = {};
    for (const q of search.value) {
      temp[q.column] = q.value
    }
    filter.value.key = Object.assign(filter.value.key, temp);
    paginationLoad()
  }
  const paginationLoad = () => {
    store.dispatch(action, filter.value);
  }
  const searchEvent = () => {
    updateFilterObject();
  }
  const discardEvent = () => {
    search.value = search.value.map(item => Object.assign(item, {
      name: item.name,
      type: item.type,
      column: item.column,
      operator: item.operator,
      value: item.type === 'HIDDEN' ? item.value : ''
    }))
    updateFilterObject()
  }
  updateFilterObject()
  return {
    filter,
    search,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
    updateFilterObject,
    searchEvent,
    discardEvent,
  }
}

export const LoadPanel = () => {
  const store = useStore();
  const loadData = (id, type) => {
    store.dispatch('LOAD_PANEL_DATA', {id: id, type: type})
  }
  return {
    loadData
  }
}

export const LoadBaseModal = () => {
  const store = useStore();
  const deleteBaseEntity = (id, type) => {
    store.dispatch('SHOW_MODAL', {id: id, type: type})
  }
  return {
    deleteBaseEntity
  }
}

export const LoadTagModal = () => {
  const store = useStore();
  const loadTagModal = (id, type) => {
    store.dispatch('SHOW_TAG_MODAL', {objectId: id, objectType: type, processType: 'MEDICAL_REVIEW'})
  }
  const loadProcessTagModal = (id, type, processType) => {
    store.dispatch('SHOW_TAG_MODAL', {objectId: id, objectType: type, processType: processType})
  }
  return {
    loadProcessTagModal,
    loadTagModal
  }
}
export const ReportChartEvent = (ACTION, id) => {
  const store = useStore();
  const filterChart = ref({
    paginationPage: 1,
    paginationSize: 20,
    key: {order: 'desc'}
  })
  const loadReportChart = () => {
    store.dispatch(ACTION, {id: id.value, filter: filterChart.value})
  }
  const handleUpdatePageReportChart = function (event) {
    filterChart.value.paginationPage = event;
    loadReportChart()
  };
  const handleUpdateSizeReportChart = function (event) {
    filterChart.value.paginationPage = 1;
    filterChart.value.paginationSize = Number(event);
    loadReportChart()
  };
  loadReportChart()
  return {
    filterChart,
    loadReportChart,
    handleUpdatePageReportChart,
    handleUpdateSizeReportChart
  }
}


export const TeamSelect = () => {
  const teams = ref([]);
  const filterGroups = ref({
    paginationPage: 1,
    paginationSize: 1000,
    key: {order: 'desc'}
  })

  const loadTeam = () => {
    GroupService.findAll<any>(filterGroups.value).then(res => {
      teams.value = res.data
    })
  }
  loadTeam()
  return {
    teams
  }
}

export const RegionSelect = () => {
  const regions = ref([]);
  const loadRegions = () => {
    MetadataService.getRegions().then(res => {
      regions.value = res;
    });
  }

  loadRegions();
  return {
    regions,
    loadRegions,
  }
}

export const userRoleCheck = () => {
  const store = useStore();
  const user = computed(() => store.getters.currentUser);
  const isAdmin = () => {
    const hasRoleAdmin = user?.value?.roles?.find(item => item.name === 'ROLE_ADMIN')
    return !!hasRoleAdmin;
  }
  return {
    isAdmin
  }
}